.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* ::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: #f1f1f1;
  border-radius: 6px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
}

.left {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  /* Adjust the size as needed */
  height: 50px;
  /* Adjust the size as needed */
  margin-right: 10px;
}

.company-name {
  font-size: 1.5em;
}

.right {
  display: flex;
  align-items: center;
}

.admin-button {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 8px 16px;
  font-size: 1em;
  margin-right: 10px;
  cursor: pointer;
}

.profile-icon {
  width: 30px;
  /* Adjust the size as needed */
  height: 30px;
  /* Adjust the size as needed */
  border-radius: 50%;
}

.PhoneInputInput {
  padding: 10.5px 14px;
  margin-top: 0.7em;
  box-sizing: content-box;
  padding-left: 0.4em;
  padding-right: 0.4em;
  border-radius: 0.2em;
  border: 0.1px solid #c4c4c4;
  outline: 1px solid #c4c4c4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 1px;
}

.makeStyles-modal-2 {
  z-index: 9999 !important;
}

.custom-toast {
  background-color: white;
}



.custom-toast-progress {
  background-color: #24003d !important;
}

/* For success icon */
/* .custom-toast.success .Toastify__toast-body svg {
  fill: #24003d;
} */

/* For error icon */
/* .custom-toast.error .Toastify__toast-body svg {
  fill: red;
} */

/* For success icon */
.Toastify__toast--success .Toastify__toast-body svg {
  fill: #24003d;
}

/* For error icon */
.Toastify__toast--error .Toastify__toast-body svg {
  fill: red;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #24003d !important;
}

.circle-emoji {
  width: 15px;
  height: 15px;
  background-color: #24003d;
  border-radius: 30px;
}